<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat">

            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>姓名：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.name" placeholder="请输入内容" clearable></el-input>
                </div>
                <div class="baseSearchOneLeft">
                    <span>身份证号：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.idcard" placeholder="请输入内容" clearable></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
            </div>
        </div>
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne7">
                <a href="./files/批量导入住宿信息模版.xls" class="downMuban" style="float: left;" download="批量导入住宿信息模版.xlsx">
                    下载模版
                </a>
                <!-- <el-button type="primary" @click="openExport" style="margin-left: 10px;">下载数据</el-button> -->
                <el-upload ref="importUpload" style="margin-left: 10px;" action="" :on-change="importHandleChange"
                    :file-list="files" :auto-upload="false" accept=".xls,.cvs,.xlsx" list-type="text"
                    :show-file-list="false">
                    <el-button type="primary">导入数据</el-button>
                </el-upload>
                <el-button type="primary" @click="addUser" style="margin-left: 10px;">添加</el-button>

            </div>
        </div>

        <div class="baseTable">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData">
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="idcard" label="身份证号"></el-table-column>
                <el-table-column prop="classteacher" label="班主任"></el-table-column>
                <el-table-column prop="classteacher_tel" label="班主任电话"></el-table-column>
                <el-table-column prop="roominfo" label="宿舍信息"></el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-link class="tableButton" type="danger" @click.prevent="openRefund(scope.row)">
                            删除
                        </el-link>
                        <el-link class="tableButton" type="danger" @click.prevent="eidtUser(scope.row)">
                            编辑
                        </el-link>
                    </template>
                </el-table-column>
            </publicTable>
        </div>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent"
                :publicPageTotal="{ total: total, pageSizes: [20, 50, 100], pageSize: pageSize }"
                @pageChange="getTableData">
            </publicPage>
        </div>
        <el-dialog v-model="dialogFormVisible" title="添加住宿信息">
            <el-form :model="form" :rules="rules" ref="ruleFormRef">
                <el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
                    <el-input v-model="form.name" clearable />
                </el-form-item>
                <el-form-item label="身份证号" :label-width="formLabelWidth" prop="idcard">
                    <el-input v-model="form.idcard" clearable />
                </el-form-item><el-form-item label="班主任" :label-width="formLabelWidth" prop="classteacher">
                    <el-input v-model="form.classteacher" clearable />
                </el-form-item><el-form-item label="班主任电话" :label-width="formLabelWidth" prop="classteacher_tel">
                    <el-input v-model="form.classteacher_tel" clearable />
                </el-form-item>
                <el-form-item label="宿舍信息" :label-width="formLabelWidth"  prop="roominfo">
                    <el-input v-model="form.roominfo" clearable />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取消</el-button>
                    <el-button type="primary" @click="addUserSure()">
                        确认
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>

import publicPage from '../../components/publicPage.vue'

import publicTable from '../../components/publicTable.vue'

import XLSX from 'xlsx';

import { businessGet, businessPost, businessDel, businessPut } from '../../api/business.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { utilsExportExcel, utilsUploadFile } from '../../utils/utils.js'
export default {
    name: "dormitory",
    components: {
        publicPage,
        publicTable
    },
    data() {
        return {
            batch_no_list: [],
            searchForm: {
                idcard: "",
            },
            files: [],
            rules: {
                name: [
                    { required: true, message: '请输入学生姓名', trigger: 'blur' },
                ],
                idcard: [
                    { required: true, message: '请输入学生身份证号', trigger: 'blur' },
                ],
                classteacher: [
                    { required: true, message: '请输入班主任名称', trigger: 'blur' },
                ],
                classteacher_tel: [
                    { required: true, message: '请输入班主任电话', trigger: 'blur' },
                ],
                roominfo: [
                    { required: true, message: '请输入学生宿舍信息', trigger: 'blur' },
                ],
            },
            dialogFormVisible: false,
            formLabelWidth: "140px",
            form: {
                name: "",
                idcard: "",
                classteacher: "",
                classteacher_tel: "",
                roominfo: "",
            },
            searchHeight: 300,
            tableData: [],
            total: 0,
            pageSize: 20,
        }
    },
    mounted() {
        this.get_batch_no_list();
        this.$nextTick(() => {
            let baseSearchDom = document.querySelector('.baseSearch');
            let baseOperateDom = document.querySelector('.baseOperate');
            let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight;
            this.searchHeight = searchHeight;
            this.cleanAll();
        })
    },

    methods: {
        eidtUser(row) {
            this.form = row;
            this.dialogFormVisible = true;

        },
        openRefund(row) {
            ElMessageBox.confirm(`您确定要删除${row.name}的住宿信息吗`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                var url = `/admin/v1/dormitory/${row.id}`
                businessDel(url).then(res => {
                    if (res.data.status == 200) {
                        this.getTableData();
                        ElMessage.success("删除成功")
                    }
                    else {
                        ElMessage({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })


            })

        },
        importHandleChange(file) {
            var that = this;
            utilsUploadFile(file.raw, 'import').then(res => {
                that.importUser(res.file_id)
            })
        },
        importUser(id) {
            let url = '/admin/v1/dormitory/import';
            businessPost(url, { file_id: id }).then(res => {
                if (res.data.status == 200) {
                    this.getTableData()
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })

        },
        addUserSure() {
            var that = this;
            this.$refs["ruleFormRef"].validate((valid) => {
                if (valid) {
                    that.sumbitData()
                } else {
                    that.$message({
                        showClose: true,
                        message: "请填写完整信息后提交",
                        type: 'warning'
                    });
                }
            });

        },
        sumbitData() {
            var p = this.form
            if (p.id) {
                let url = `/admin/v1/dormitory/${p.id}`;
                businessPut(url, p).then(res => {
                    if (res.data.status == 200) {
                        this.getTableData();
                        this.dialogFormVisible = false;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })

            }
            else {
                let url = '/admin/v1/dormitory';
                businessPost(url, p).then(res => {
                    if (res.data.status == 200) {
                        this.getTableData()
                        this.dialogFormVisible = false;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            }


        },
        addUser() {
            this.form = {
                name: "",
                idcard: "",
                classteacher: "",
                classteacher_tel: "",
                roominfo: "",
            }
            this.dialogFormVisible = true;
        },
        openExport() {
            let url = '/admin/v1/dormitory?is_export=1';
            utilsExportExcel(url)
        },
        exportExcel() {
            //数据表格
            var aoa = [
                ['表格在此', '', '', ''],
                ['', '', '', ''],
                ['姓名', '性别', '年龄', '注册时间'],
                ['张三', '男', 18, new Date()],
                ['李四', '女', 22, new Date()]
            ];

            //创建book
            var wb = XLSX.utils.book_new();

            var ws = XLSX.utils.aoa_to_sheet(aoa);
            //设置列宽
            ws['!cols'] = [
                { width: 50 },
                { width: 15 },
                { width: 15 },
                { width: 10 }
            ];
            ws['!merges'] = [
                // 设置A1-C1的单元格合并
                {
                    s: { r: 0, c: 0 },
                    e: { r: 1, c: 3 }
                }
            ];

            ws['A3'].l = { Target: "http://sheetjs.com", Tooltip: "Find us @ SheetJS.com!" };

            var aoa2 = [
                ['姓名', '性别', '年龄', '注册时间'],
                ['张三', '男', 18, new Date()],
                ['李四', '女', 22, new Date()]
            ];

            var ws2 = XLSX.utils.aoa_to_sheet(aoa2);

            ws2["A1"].s = {
                font: { sz: 14, bold: true, vertAlign: true },
                alignment: { vertical: "center", horizontal: "center" },
                fill: { bgColor: { rgb: "E8E8E8" }, fgColor: { rgb: "E8E8E8" } }
            };

            var aoa3 = [
                ['姓名', '性别', '年龄', '注册时间'],
                ['张三', '男', 18, new Date()],
                ['李四', '女', 22, new Date()]
            ];

            var ws3 = XLSX.utils.aoa_to_sheet(aoa3);
            ws3["!margins"] = { left: 0.25, right: 0.25, top: 0.75, bottom: 0.75, header: 0.3, footer: 0.3 }

            //sheet写入book
            XLSX.utils.book_append_sheet(wb, ws, "操作合并，列宽");
            XLSX.utils.book_append_sheet(wb, ws2, "正常");
            XLSX.utils.book_append_sheet(wb, ws3, "操作间隔");

            //输出

            var timestamp = (new Date()).getTime();
            XLSX.writeFile(wb, "file" + timestamp + ".csv");
        },
        cleanAll() {
            this.search();
        },
        search() {
            this.$refs.publicPageUserStudent.currentPage = 1;
            this.getTableData();
        },
        getTableData() {
            let url = '/admin/v1/dormitory?page=' + this.$refs.publicPageUserStudent.currentPage + `&page_pre=${ this.$refs.publicPageUserStudent.pageSize}`
            for (let k in this.searchForm) {
                if (this.searchForm[k]) {
                    url = url + '&' + k + '=' + this.searchForm[k];
                }
            }
            this.tableLoading = true;
            businessGet(url).then(res => {
                this.tableLoading = false;
                if (res.data.status == 200) {
                    this.total = res.data.data.count;
                    this.tableData = res.data.data.list;
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        },
        get_batch_no_list() {
            let url = '/admin/v1/sign_up?page=1&page_pre=500';
            businessGet(url).then(res => {
                if (res.data.status == 200) {
                    let newArray = [];
                    res.data.data.list.forEach(item => {
                        let a = {
                            value: item.batch_no,
                            label: item.batch_name,
                            fields: item.fields
                        }
                        newArray.push(a);
                    })
                    this.batch_no_list = newArray;
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        }
    }
}
</script>

<style scoped>
@import "../../assets/css/base.css";

.baseOperateOne7 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
</style>
